import React, { useRef } from 'react';
import { gsap } from 'gsap/dist/gsap';
import Banner from '../components/Banner/Banner';
import Custom404 from '../containers/Custom404/Custom404';
import Layout from '../components/Layout';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Page404 = () => {
  return (
    <Layout pathRoot={'404'}>
      <Banner title="La page n'existe pas" />
      <Custom404 />
    </Layout>
  );
};

export default Page404;
