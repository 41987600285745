import React from 'react';
import styles from './Custom404.module.scss';
import Link from 'next/link';

const Custom404 = () => {
  return (
    <div className={styles.Custom404}>
      <video
        src='/videos/Akarah404.webm'
        autoPlay='true'
        muted='muted'
        loop
      ></video>

      <h2>OUPS !</h2>
      <p>La page que vous recherchez n'est pas ou plus accessible.</p>

      <Link href={'/'}>
        <a>Revenir à l'accueil</a>
      </Link>
    </div>
  );
};

export default Custom404;
