import React, { useEffect, useRef } from 'react';
import styles from './Banner.module.scss';
import Images from '../Images/Images';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Banner = ({ title, banner = null, icon = null, small = false }) => {
  const bannerRef = useRef(null);
  useEffect(() => {
    // Turn navbar in white
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: bannerRef.current,
            scrub: true,
            start: `top ${25 + 20 + 32}`,
            end: `top ${25 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne p', { color: '#fff', duration: 1 }, 0)
          .fromTo(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            {
              duration: 1,
              '-webkit-filter': 'brightness(5)',
              filter: 'brightness(5)',
            },
            0
          );
      },

      // small
      '(min-width: 600px) and (max-width: 799px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: bannerRef.current,
            scrub: true,
            start: `top ${20 + 20 + 32}`,
            end: `top ${20 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne p', { color: '#fff', duration: 1 }, 0)
          .fromTo(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            {
              duration: 1,
              '-webkit-filter': 'brightness(5)',
              filter: 'brightness(5)',
            },
            0
          );
      },

      // mobile
      '(max-width: 599px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: bannerRef.current,
            scrub: true,
            start: `top ${15 + 15 + 32}`,
            end: `top ${15 + 15}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne p', { color: '#fff', duration: 1 }, 0)
          .fromTo(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            {
              duration: 1,
              '-webkit-filter': 'brightness(5)',
              filter: 'brightness(5)',
            },
            0
          );
      },
    });
  }, []);

  return (
    <section className={styles.Banner} ref={bannerRef}>
      <div className={styles.Banner__background}>
        <Images url={banner} alt='' role='presentation' />
      </div>
      <img
        src={icon || '/img/svg/gemme-wireframe.svg'}
        alt=''
        width='160'
        role='presentation'
        className={`${styles.Banner__bgicon} ${
          small === true ? styles.Banner__bgicon__sm : {}
        }`}
        style={{ opacity: icon === null ? 1 : 0.3 }}
      />

      <h1
        id={'t-' + title}
        className={`${small === true ? styles.Banner__titleSM : ''}`}
      >
        {title}
      </h1>
    </section>
  );
};

export default Banner;
